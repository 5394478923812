import { graphql, Link, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const logo = require('../images/main__logo.svg')
const fb = require('../images/fb-01.svg')
const ln = require('../images/linkedin-01.svg')

const q = graphql`
  query {
    allWordpressWpLang {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const Footer = () => (
  <StaticQuery
    query={q}
    render={data => {
      const langs = data.allWordpressWpLang.edges.map((e, i) => {
        return <li key={i}>{e.node.name}</li>
      })

      return (
        <footer className={'footer'}>
          <section className="footer__section">
            <div className="container">
              <img className={'footer__logo'} src={logo} alt="Logo" />
            </div>
            <div className="grid footer__info">
              <div className="col-8 col-sm-12 footer__contact">
                <address className="footer__address">
                  T: +48 535207080 <br />
                  E: kazik@kazikkoduje.pl
                </address>
                <ul className="social">
                  <li>
                    <a
                      target="_blank"
                      href={
                        'https://pl-pl.facebook.com/pages/category/Science--Technology---Engineering/KazikKoduje-157586944778740/'
                      }
                    >
                      <img src={fb} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/kazkikoduje/about/"
                    >
                      <img src={ln} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-4 col-sm-12 footer__langs">
                <h5 className={'footer__h5'}>Technologie: </h5>
                <ul className="footer__ul">{langs}</ul>
              </div>
            </div>
          </section>
          <section className="eu-info">
            <div className="container">
              <div className="eu-info__logos">
                <img className="logo" src="/img/FE.jpg" alt="logo FE" />
                <img className="logo" src="/img/UE.jpg" alt="logo UE" />
                <img className="logo" src="/img/RP.png" alt="logo RP" />
              </div>
              <div className="eu-info__text">
                "Zwiększenie odporności na czynniki losowe poprzez
                unowocześnienie procesu prowadzenia kursów i szkoleń
                programistycznych dzięki wdrożeniu innowacyjnej platformy
                e-learningowej"
                <br></br>
                <br></br>
                Przedmiotem projektu jest wdrożenie platformy e-learningowej,
                która ma za zadanie stworzyć intuicyjne i przyjazne środowisko
                do nauki i praktyki programowania dla swoich kursantów, co
                pozwoli Wnioskodawcy na uniknięcie kolejnych, negatywnych dla
                działalności konsekwencji COVID-19.
                <br></br>
                <br></br>
                Platforma e-learningowa wpłynie na zmianę sposóbu realizacji
                szkoleń przenosząc wszystkie procesy biznesowe do Internetu,
                wykorzystując potencjał rynku globalnego oraz możliwość
                całkowitej pracy zdalnej z klientami oraz kursantami.
                <br></br>
                <br></br>
                Projekt jest współfinansowany w ramach Europejskiego Funduszu
                Rozwoju Regionalnego ze środków Programu Operacyjnego
                Inteligentny Rozwój. Sfinansowano w ramach reakcji Unii na
                pandemię COVID-19.
                <br></br>
                <br></br>
                Oś priorytetowa VI: Zwiększenie potencjału przedsiębiorstw i
                przygotowanie do cyfrowej transformacji w kontekście pandemii
                COVID-19 Działanie: 6.2 Wsparcie MŚP w obszarze cyfryzacji -
                Bony na cyfryzację
                <br></br>
                <br></br>
                Beneficjent: KazikKoduje Marcin Kieruzel<br></br>
                Całkowita wartość projektu: 275 000,00 zł<br></br>
                Wartość dofinansowania: 233 750,00<br></br>
                Okres realizacji: październik 2022 - marzec 2023
              </div>
            </div>
          </section>
          <p className="container footer__copy">
            kazikkoduje &copy; 2018 | Wszystkie prawa zastrzeżone | grafika:
            pola projektowe
          </p>
        </footer>
      )
    }}
  ></StaticQuery>
)

export default Footer
