import PropTypes from 'prop-types'
import React from 'react'
import {StaticQuery, graphql, Link} from "gatsby"
const logo = <svg className={"logo__svg"} version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  width="297px" height="36px" viewBox="0 0 297 36" enableBackground="new 0 0 297 36" xmlSpace="preserve">
<g>
	<g>
		<g>
			<path d="M1.133,8.11c0.264,0.181,0.568,0.296,0.913,0.346C2.393,8.504,2.729,8.53,3.061,8.53c0.229,0,0.518-0.009,0.864-0.026
				c0.346-0.016,0.674-0.088,0.986-0.221c0.314-0.132,0.585-0.34,0.816-0.619C5.957,7.386,6.073,7,6.073,6.504
				c0-0.098-0.018-0.279-0.049-0.543c-0.033-0.264-0.067-0.545-0.101-0.84C5.892,4.824,5.859,4.555,5.826,4.306
				c-0.032-0.247-0.049-0.42-0.049-0.519c0-0.922,0.278-1.555,0.839-1.902c0.56-0.344,1.219-0.517,1.976-0.517
				c0.038,0,0.069,0.006,0.106,0.009V0.678c-0.019,0-0.037-0.002-0.057-0.002c-1.087,0-2.133,0.114-3.137,0.346
				c-1.006,0.23-1.9,0.566-2.692,1.012c-0.79,0.444-1.426,1.005-1.902,1.68C0.435,4.388,0.193,5.155,0.193,6.011
				c0,0.559,0.092,1.003,0.272,1.334C0.646,7.674,0.869,7.928,1.133,8.11z"/>
		</g>
	</g>
    <path d="M60.851,22.32c-0.873,0.132-1.688,0.197-2.444,0.197c-1.68,0-2.923-0.296-3.729-0.889
		c-0.808-0.592-1.309-1.415-1.507-2.468h-0.099c-0.526,1.315-1.251,2.205-2.173,2.666c-0.922,0.46-2.255,0.69-4,0.69
		c-0.79,0-1.597-0.082-2.42-0.246c-0.822-0.166-1.58-0.428-2.271-0.791c-0.691-0.36-1.251-0.862-1.679-1.506
		c-0.429-0.642-0.642-1.424-0.642-2.346c0-0.954,0.188-1.753,0.567-2.395c0.378-0.643,0.872-1.186,1.481-1.63
		c0.609-0.443,1.301-0.806,2.074-1.087c0.773-0.279,1.572-0.551,2.396-0.814c1.349-0.395,2.444-0.732,3.284-1.012
		c0.839-0.279,1.488-0.576,1.95-0.89c0.46-0.312,0.781-0.667,0.963-1.062c0.181-0.395,0.271-0.905,0.271-1.531V4.197
		c0-0.889-0.214-1.695-0.642-2.42c-0.429-0.724-1.234-1.086-2.42-1.086c-0.758,0-1.416,0.172-1.976,0.518s-0.839,0.98-0.839,1.902
		c0,0.098,0.016,0.271,0.049,0.518c0.032,0.247,0.065,0.519,0.099,0.814c0.032,0.297,0.065,0.577,0.099,0.84
		c0.033,0.264,0.05,0.445,0.05,0.544c0,0.493-0.116,0.881-0.346,1.16c-0.23,0.28-0.503,0.485-0.815,0.617s-0.642,0.206-0.987,0.223
		s-0.634,0.024-0.864,0.024c-0.329,0-0.666-0.024-1.012-0.074c-0.346-0.049-0.65-0.164-0.914-0.346
		c-0.264-0.181-0.486-0.436-0.666-0.766c-0.182-0.328-0.272-0.773-0.272-1.333c0-0.856,0.239-1.621,0.716-2.296
		c0.478-0.675,1.111-1.235,1.902-1.68c0.789-0.444,1.686-0.78,2.69-1.012C47.73,0.115,48.776,0,49.862,0
		c1.251,0,2.428,0.098,3.531,0.296c1.103,0.197,2.057,0.56,2.864,1.087c0.807,0.526,1.448,1.242,1.926,2.147s0.716,2.05,0.716,3.433
		v12.246c0,0.626,0.091,1.178,0.271,1.654c0.182,0.479,0.634,0.717,1.358,0.717c0.428,0,0.782-0.082,1.062-0.247
		s0.551-0.396,0.814-0.691l0.445,0.444C62.389,21.777,61.723,22.188,60.851,22.32z M52.776,9.629
		c-0.066,0.198-0.189,0.388-0.37,0.568c-0.182,0.182-0.503,0.387-0.964,0.617c-0.625,0.297-1.251,0.585-1.876,0.864
		c-0.626,0.28-1.185,0.634-1.679,1.062s-0.897,0.955-1.21,1.58c-0.313,0.626-0.47,1.432-0.47,2.42c0,0.395,0.025,0.856,0.074,1.383
		c0.05,0.527,0.181,1.038,0.396,1.531c0.214,0.493,0.534,0.913,0.963,1.259c0.427,0.346,1.021,0.519,1.777,0.519
		c0.428,0,0.848-0.099,1.26-0.297c0.411-0.197,0.781-0.501,1.11-0.913c0.329-0.411,0.594-0.954,0.791-1.63
		c0.197-0.674,0.296-1.488,0.296-2.444V9.629H52.776z"/>
    <path d="M73.532,1.383c-0.889,0-1.646,0.106-2.271,0.32c-0.626,0.215-1.178,0.535-1.654,0.963c-0.479,0.428-0.897,0.972-1.26,1.63
		c-0.362,0.659-0.74,1.416-1.136,2.271h-0.543l0.741-5.876h17.629l-11.95,20.493h6.024c1.02,0,1.868-0.181,2.543-0.543
		s1.234-0.831,1.68-1.407c0.444-0.575,0.806-1.234,1.086-1.976c0.279-0.741,0.551-1.473,0.814-2.198h0.544l-0.544,6.815H65.927
		l12-20.493H73.532z"/>
    <path d="M99.133,0.691v20.493h3.309v0.691H89.799v-0.691h3.31V1.383h-3.31V0.691H99.133z"/>
    <path d="M180.542,0.913c1.465,0.609,2.741,1.425,3.827,2.444c1.087,1.021,1.934,2.215,2.544,3.58
		c0.608,1.367,0.913,2.808,0.913,4.321c0,1.548-0.305,3.005-0.913,4.37c-0.61,1.367-1.457,2.561-2.544,3.58
		c-1.086,1.021-2.362,1.828-3.827,2.42c-1.465,0.593-3.054,0.889-4.766,0.889s-3.301-0.296-4.766-0.889
		c-1.465-0.592-2.74-1.398-3.826-2.42c-1.087-1.02-1.935-2.213-2.543-3.58c-0.61-1.365-0.914-2.822-0.914-4.37
		c0-1.514,0.304-2.954,0.914-4.321c0.608-1.365,1.456-2.559,2.543-3.58c1.086-1.02,2.361-1.835,3.826-2.444
		C172.476,0.305,174.064,0,175.776,0S179.077,0.305,180.542,0.913z M178.789,20.963c0.724-0.609,1.259-1.408,1.604-2.396
		s0.561-2.124,0.643-3.406c0.082-1.285,0.123-2.584,0.123-3.902c0-1.316-0.041-2.608-0.123-3.876S180.739,4.987,180.394,4
		c-0.346-0.988-0.881-1.786-1.604-2.396c-0.725-0.608-1.729-0.913-3.013-0.913c-1.283,0-2.288,0.305-3.012,0.913
		c-0.725,0.609-1.26,1.407-1.605,2.396c-0.346,0.987-0.56,2.115-0.642,3.383c-0.083,1.268-0.124,2.56-0.124,3.876
		c0,1.318,0.041,2.617,0.124,3.902c0.082,1.282,0.296,2.419,0.642,3.406s0.881,1.786,1.605,2.396
		c0.724,0.609,1.729,0.913,3.012,0.913C177.061,21.876,178.064,21.572,178.789,20.963z"/>
    <path d="M190.843,1.383V0.691h5.531c1.383,0,2.658-0.032,3.827-0.1c1.168-0.065,2.444-0.099,3.827-0.099
		c1.811,0,3.457,0.28,4.938,0.84s2.748,1.326,3.802,2.296c1.054,0.972,1.86,2.107,2.42,3.408s0.84,2.708,0.84,4.222
		c0,1.548-0.28,2.972-0.84,4.271c-0.56,1.301-1.366,2.437-2.42,3.407c-1.054,0.972-2.32,1.729-3.802,2.271s-3.128,0.815-4.938,0.815
		c-1.383,0-2.659-0.024-3.827-0.074c-1.169-0.05-2.444-0.074-3.827-0.074h-5.531v-0.691h3.26V1.383H190.843z M209.362,11.259
		c0-1.316-0.05-2.575-0.148-3.778c-0.1-1.201-0.346-2.271-0.741-3.209c-0.395-0.938-0.987-1.688-1.777-2.247s-1.877-0.84-3.26-0.84
		c-0.197,0-0.452,0-0.765,0c-0.313,0-0.635,0.009-0.964,0.024c-0.329,0.018-0.642,0.042-0.938,0.074
		c-0.296,0.033-0.51,0.066-0.642,0.1v19.802c0.362,0.066,0.896,0.116,1.605,0.148c0.707,0.033,1.324,0.05,1.852,0.05
		c1.251,0,2.255-0.279,3.013-0.84c0.757-0.56,1.341-1.3,1.753-2.223c0.411-0.921,0.683-1.991,0.814-3.21
		C209.296,13.893,209.362,12.609,209.362,11.259z"/>
    <path d="M227.534,0.691v15.654c0,1.251,0.247,2.313,0.74,3.185c0.494,0.872,1.366,1.309,2.617,1.309
		c0.758,0,1.449-0.18,2.074-0.543c0.625-0.362,1.161-0.847,1.605-1.457c0.444-0.608,0.79-1.325,1.037-2.147
		c0.246-0.823,0.37-1.68,0.37-2.568V1.383h-3.259V0.691h9.283v20.493h3.26v0.691h-9.186v-4h-0.099
		c-0.264,0.725-0.659,1.358-1.185,1.901c-0.527,0.543-1.129,1.004-1.803,1.384c-0.676,0.378-1.392,0.657-2.148,0.839
		c-0.758,0.18-1.498,0.271-2.223,0.271c-2.305,0-4.066-0.502-5.283-1.506c-1.219-1.004-1.827-2.709-1.827-5.111V1.383h-3.31V0.691
		H227.534z"/>
    <path d="M248.872,1.383V0.691h9.333V23.95c0,1.712-0.141,3.3-0.42,4.765c-0.28,1.465-0.741,2.741-1.383,3.828
		c-0.642,1.086-1.489,1.934-2.543,2.543S251.505,36,249.958,36c-1.777,0-3.234-0.371-4.37-1.111
		c-1.136-0.741-1.704-1.771-1.704-3.087c0-1.054,0.28-1.777,0.84-2.173c0.561-0.395,1.186-0.592,1.877-0.592
		c0.822,0,1.464,0.188,1.926,0.567c0.46,0.378,0.691,0.913,0.691,1.604c0,0.362-0.066,0.782-0.198,1.26
		c-0.132,0.477-0.197,0.896-0.197,1.26c0,0.328,0.065,0.674,0.197,1.037c0.132,0.361,0.593,0.543,1.383,0.543
		c0.593,0,1.037-0.182,1.333-0.543c0.297-0.363,0.445-1.186,0.445-2.47V1.383H248.872z"/>
    <path d="M270.199,11.703c0,1.186,0.05,2.379,0.148,3.58c0.099,1.203,0.361,2.289,0.79,3.26c0.428,0.971,1.053,1.77,1.876,2.395
		c0.823,0.626,1.976,0.938,3.457,0.938c1.316,0,2.428-0.247,3.333-0.741c0.905-0.493,1.646-1.061,2.223-1.703
		s1.004-1.275,1.284-1.901c0.279-0.625,0.436-1.087,0.469-1.383l0.691,0.247c-0.165,0.626-0.444,1.293-0.839,2
		c-0.396,0.709-0.938,1.367-1.63,1.976c-0.691,0.609-1.548,1.119-2.568,1.53c-1.021,0.412-2.255,0.617-3.704,0.617
		c-1.712,0-3.309-0.255-4.789-0.766c-1.482-0.509-2.774-1.25-3.877-2.222c-1.104-0.971-1.968-2.155-2.593-3.556
		c-0.626-1.397-0.938-2.987-0.938-4.766c0-1.414,0.297-2.797,0.889-4.148c0.593-1.349,1.408-2.543,2.445-3.58
		s2.254-1.876,3.654-2.518C271.919,0.32,273.425,0,275.039,0c1.151,0,2.304,0.181,3.456,0.543s2.189,0.922,3.111,1.679
		c0.922,0.758,1.68,1.737,2.271,2.939c0.593,1.201,0.889,2.641,0.889,4.319h-14.567V11.703z M278.101,5.48
		c0-0.36-0.018-0.806-0.05-1.333c-0.033-0.526-0.147-1.054-0.346-1.58c-0.197-0.526-0.519-0.971-0.963-1.333
		s-1.062-0.543-1.852-0.543c-1.12,0-1.983,0.264-2.593,0.789c-0.609,0.527-1.062,1.194-1.357,2
		c-0.297,0.808-0.486,1.681-0.568,2.617c-0.082,0.939-0.141,1.836-0.173,2.691h7.901V5.48z"/>
    <path d="M293.3,27.037c0.955-0.939,1.433-2,1.433-3.186c0-0.527-0.083-0.955-0.247-1.284c-0.165-0.329-0.33-0.56-0.494-0.691
		c-0.296,0.165-0.625,0.279-0.987,0.346c-0.362,0.065-0.725,0.099-1.087,0.099c-0.329,0-0.699-0.066-1.11-0.197
		c-0.412-0.132-0.782-0.328-1.111-0.593c-0.329-0.263-0.609-0.617-0.84-1.062s-0.346-0.979-0.346-1.605
		c0-0.625,0.115-1.16,0.346-1.604s0.526-0.806,0.89-1.087c0.361-0.279,0.765-0.484,1.209-0.617c0.445-0.131,0.881-0.197,1.309-0.197
		c1.251,0,2.321,0.511,3.211,1.531c0.889,1.021,1.333,2.469,1.333,4.346c0,1.25-0.231,2.37-0.691,3.357
		c-0.462,0.988-1.046,1.868-1.753,2.643c-0.709,0.773-1.475,1.448-2.297,2.024c-0.823,0.575-1.58,1.078-2.271,1.507l-0.346-0.643
		C291.062,29.004,292.345,27.975,293.3,27.037z M290.188,1.037C290.88,0.346,291.703,0,292.658,0c0.954,0,1.778,0.346,2.47,1.037
		s1.036,1.514,1.036,2.469s-0.345,1.777-1.036,2.469s-1.516,1.037-2.47,1.037c-0.955,0-1.778-0.346-2.47-1.037
		c-0.69-0.691-1.036-1.514-1.036-2.469S289.498,1.729,290.188,1.037z"/>
    <polygon points="131.003,21.185 119.694,6.618 125.226,1.383 128.386,1.383 128.386,0.691 120.633,0.691 120.633,1.383
		124.237,1.383 115.053,9.827 114.806,9.827 114.806,0.691 108.78,0.691 108.78,21.185 105.521,21.185 105.521,21.876
		117.867,21.876 117.867,21.185 114.806,21.185 114.806,10.518 115.053,10.518 123.398,21.185 119.843,21.185 119.843,21.876
		132.929,21.876 132.929,21.185 	"/>
    <polygon points="160.677,21.185 149.368,6.618 154.898,1.383 158.06,1.383 158.06,0.691 150.306,0.691 150.306,1.383
		153.911,1.383 144.726,9.827 144.479,9.827 144.479,0.691 138.454,0.691 138.454,21.185 135.194,21.185 135.194,21.876
		147.54,21.876 147.54,21.185 144.479,21.185 144.479,10.518 144.726,10.518 153.071,21.185 149.516,21.185 149.516,21.876
		162.603,21.876 162.603,21.185 	"/>
    <polygon points="35.177,21.185 23.868,6.618 29.399,1.383 32.56,1.383 32.56,0.691 24.807,0.691 24.807,1.383 28.411,1.383
		19.227,9.827 18.979,9.827 18.979,0.691 12.954,0.691 12.954,21.185 9.695,21.185 9.695,21.876 22.041,21.876 22.041,21.185
		18.979,21.185 18.979,10.518 19.227,10.518 27.571,21.185 24.017,21.185 24.017,21.876 37.103,21.876 37.103,21.185 	"/>
</g>
</svg>




const Header = ({siteTitle}) => {

    if(typeof window === 'undefined') { return null; } let localization = window.location.pathname.split('/');

    // let lang = '';

    // switch (true) {
    //     case localization.includes('en'):
    //         lang = 'en/';
    //         break;
    //     case localization.includes('de'):
    //         lang = 'de/';
    //         break;
    //     default:
    //         lang = '';
    //         break;
    // }

    const menu = [
        {
            href: `o-nas`,
            anchor:'O nas'
        },
        {
            href: `teksty`,
            anchor:  'Teksty'
        },
        {
            href: `projekty`,
            anchor:'Projekty'
        },
        {
            href: `kontakt`,
            anchor: 'Kontakt'
        }
    ]

    let items = menu.map((e, i) => {
        return <li className={"header__li"} key={i}><Link  activeStyle={{color: "#0033cc"}}  to={`/${e.href}`}>{e.anchor}</Link></li>
    });

    return (
        <header className={"container header"}>
            <ul className={"header__menu"}>
                {items}
            </ul>
						<div>
						<Link  activeStyle={{color: "#0033cc"}}  className={"logo__link"} to="/">
                {logo}
            </Link>
						<img src='/img/UE.jpg' alt='logo' className='ue-flag' />
						</div>
          
        </header>
    )

}


Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: '',
}

export default Header
